import React from 'react'
import './App.css';

const links = [{
  label: 'Email',
  value: 'mailto:pavel@kozemirov.ru'
}, {
  label: 'Linkedin',
  value: 'https://linkedin.com/in/kozemirov'
}, {
  label: 'Github',
  value: 'https://github.com/pkozemirov'
}, {
  label: 'Telegram',
  value: 'http://t.me/kozemirov'
}]

function App() {
  return (
    <div className="App">
      <h1>Pavel Kozemirov</h1>
      <p>Web development and stuff</p>
      <p>
        {
          links.map(({ value, label }, index) => {
            return <React.Fragment key={value}>
              <a rel="noreferrer" target="_blank" href={value}>{label}</a>
              {
                index !== (links.length - 1)
                  ? <br />
                  : null
              }
            </React.Fragment>
          })
        }
      </p>
      <p className="footer">GMT+3<br /> EN / RU</p>
    </div>
  );
}

export default App;
